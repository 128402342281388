<template>
    <div>
        <v-card flat id="lead_order_datatable">
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="8" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7 text-left">
                        <p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text">Downline Lead Orders
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="4" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-row class="d-flex justify-start">
                <q-filters dark ref="filters" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :show-reset="true">
                </q-filters>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0 lead-order-table" :footer-props="{itemsPerPageOptions:[50, 100, 200, 300]}" :loading="loading" :options.sync="options">
                <template v-slot:item.AgentName="{ item }">
                    <span class="nowrap">
                        <router-link :to="'/agents/' + item.AgentCode">{{item.AgentName}}</router-link>
                    </span>
                </template>
                <template v-slot:item.SwitchboardConfig="{ item }">
                    <span>
                        <v-icon v-if="item.SwitchboardConfig && item.SwitchboardConfig.location_id">fas fa-check</v-icon>
                    </span>
                </template>
                <template v-slot:item.FillRate="{ item }">
                    <span>
                        {{ formatPercentage(item.FillRate) }}
                    </span>
                </template>
                <template v-slot:item.OrderNumber="{ item }">
                    <span>
                        <v-tooltip top v-if="item.NeedsApproval">
                            <template v-slot:activator=" { on, attrs }">
                                {{item.OrderNumber}}
                                    <v-btn icon to="/leads/approve-digital-lead-orders">
                                        <v-icon color="yellow" :x-small="true" v-on="on">fas fa-exclamation-triangle</v-icon>
                                    </v-btn>
                            </template>
                            <span>This order requires approval!</span>
                        </v-tooltip>
                        <v-tooltip top v-else-if="item.OrderStatus == 'Requires Balance'">
                            <template v-slot:activator=" { on, attrs }">
                                {{item.OrderNumber}}
                                    <v-btn icon to="/leads/approve-digital-lead-orders">
                                        <v-icon color="yellow" :x-small="true" v-on="on">fas fa-exclamation-triangle</v-icon>
                                    </v-btn>
                            </template>
                            <span>This order requires balance! Please have the agent update their credit card!</span>
                        </v-tooltip>
                        <span v-else>{{item.OrderNumber}}</span>
                    </span>
                </template>
                <template slot="body.append">
                    <tr>
                        <th class="title text-center" v-for="(header,ix) in the_headers" :key="ix">
                            <span v-if="header.value == 'AgentName'">
                                <v-btn v-if="the_data.length > 0" color="secondary" @click="exportResults">
                                    Download <v-icon>fas fa-download</v-icon>
                                </v-btn>
                            </span>
                            <span v-else-if="header.value == 'QtyRequested'">
                                {{ sumField('QtyRequested') }}
                            </span>
                            <span v-else-if="header.value == 'QtyFilled'">
                                {{ sumField('QtyFilled') }}
                            </span>
                            <span v-else-if="header.value == 'FillRate'">
                                {{ formatPercentage(sumField('QtyFilled') / sumField('QtyRequested')) }}
                            </span>
                        </th>
                    </tr>
                    
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
const { Parser } = require('json2csv');
import QFilters from '@/components/utils/QFilters.vue'
import moment from 'moment'
import { weekdiff } from '@/components/utils/BusinessTimeDateDiff.js'

export default {
    name: "QBaseshopLeadOrderReportTable",
    props: ['agentCode'],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead_order',
            //use this to "preset" a filter
            filters: {
                //'LeadType': "Quility Digital Lead",
                //'GroupBy' : 'State',
                //"OrderType": "Standing Order",
                "Weeks" : null
            }, //these are the filters the user has set or you can preset them
            options: {
                'sortBy': ['OrderDate'], //used to set the default sorting column
                'sortDesc': [false],
            },
            the_data: [], //this will hole the data fromt he server now that we aren't using vuex anymore.
            search: null,
            selected: [],
            data_loading: false,
        }
    },
    mounted: function() {
        this.$nextTick(() => {
            //this.filters.Weeks = this.available_filters.Weeks[0]
        })
        this.refreshData()
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ["Weeks"]; //this must sync up with availableFilters in the Model in Laravel.
        },
        total_items: function() {
            return this.the_data.length;
        },
        the_headers: function() {
            var r = [{
                text: 'Agent',
                value: 'AgentName',
                align: "left"
            }, {
                text: 'Has Switchboard',
                value: 'SwitchboardConfig',
                align: "center"
            }, {
                text: 'Order #',
                value: 'OrderNumber',
                align: "left"
            }, {
                text: 'Order Type',
                value: 'OrderType',
                align: "left"
            }, {
                text: 'LeadType',
                value: 'LeadType',
                align: "left"
            }, {
                text: '# Requested',
                value: 'QtyRequested',
                align: "center"
            }, {
                text: '# Filled',
                value: 'QtyFilled',
                align: "center"
            }, {
                text: 'Fill Rate',
                value: 'FillRate',
                align: "center"
            }, {
                text: 'States',
                value: 'OrderStates',
                align: "center"
            }];
            return r;
        },

        available_filters: function() {
            var weeks = this.getAvailableBusinessDates('weeks', false, true, '2022-07-01');
            var week_selection = [];
            weeks.forEach((w) => {
                week_selection.push({
                    text : w.label,
                    value : {
                        endDate : w.endDateFormat,
                        startDate : w.startDateFormat,
                        'disableMultiple' : true
                    }
                })
            })
            return {
                //"GroupBy": ["State", "Product", "Order", "Agency", "Agent"],
                //"LeadType": ["Quility Digital Lead", "Recruiting"],
                //"OrderType": ["Standing Order", "One-time Order"],
                "Weeks" : week_selection
            };
        },
        export_fields: function() {
            var r = [];
            this.the_headers.forEach(function(item){
                r.push(item.value);
            })
            return r;
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            this.data_loading = true
            var d = this.filters.Weeks;
            var openOnly = false;
            //drop down returns null for current week... so we need to generate the correct dates. 
            if ( typeof d == 'undefined' || d == '-All-' || d.startDate == null){
                var day_of_the_week = moment().format('dddd');
                var current = moment().subtract(weekdiff[day_of_the_week]['saturday'], 'days')
                d = {
                    endDate: moment(current).add(6, 'days').format('YYYY-MM-DD'),
                    startDate: moment(current).format('YYYY-MM-DD'),
                }
                openOnly = true;
            }
            var filters = {
                //GroupBy: this.filters.GroupBy,
                //LeadType: this.filters.LeadType,
                //OrderType : this.filters.OrderType,
                startDate : d.startDate + " 00:00:00",
                endDate : d.endDate + " 23:59:59",
                'openOnly' : openOnly
            }
            var g = this;
            g.$set(g, 'the_data', []);
            QuilityAPI.getBaseshopLeadOrderReport(this.agentCode, filters, opt).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
            }).catch(function(error) {
                //g.showError("There was a problem getting the data.", error);
                //g.data_loading = false
            });
        }, 200),
        refresh: function() {
            this.the_data = []
            this.refreshData();
        },
        refreshData: function(search) {
            var opt = {
                ...this.options,
            }
            this.expanded = [];
            this.loadData(opt);
        },
        updateCurrentItems: function(items) {
            this.currentItems = items;
        },
        alignClass: function(header) {
            if (header.align == "center") {
                return "text-middle text-center"
            }
            if (header.align == "left") {
                return "text-start text-center"
            }
            if (header.align == "right") {
                return "text-end"
            }
        },
        exportResults: function() {
            var fields = this.export_fields;
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.the_data);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify('BaseshopLeadOrders_' + this.formatDate());
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.logActivity("Baseshop Lead Orders Report Export", {})
            } catch (err) {
                console.error(err);
            }

        },
        sumField(key) {
            return this.all_data.reduce((a, b) => a + (Number(b[key]) || 0), 0)
        }
    },
    watch: {
        'filters': {
            deep: true,
            handler(newV, oldV) {
                this.options.page = 1
                this.$nextTick(this.refreshData);
            }
        },
        'options': {
            deep: true,
            handler(newV) {
                /*var g = this;
                if (!this.firstPull) {
                    this.$vuetify.goTo('#' + this.entity + '_datatable', {
                        duration: 250,
                        easing: 'easeOutCubic',
                    });
                }
                this.$nextTick(g.refreshData)
                */
            }
        },
    },
    components: {
        QFilters,
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.grey_row {
    background-color: #f7f7f7;
}

.lead-order-table {
    font-size: 12px;
}

.lead-order-line-table {
    background-color: #f7f7f7;
}

.lead-order-line-table table td {
    font-size: .9em;
    width: 10%;
}
</style>
<style>
.lead-divider {
    margin: 0 6px;
}
</style>